import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>Un nou concepte ha vingut per quedar-se!</strong> Una nova manera de
                    posar-te a prova i, a més, fer-ho de manera personalitzada i amb seguiment
                    mensual. Una sessió presencial d'una duració de 5 hores aproximadament al nostre
                    centre de formació de Sant Cugat.
                </p>
                <p>
                    És una nova forma de treballar i veure com estàs evolucionant, però alhora,
                    sortir de la sessió amb un resultat numèric orientatiu d'en quin moment estàs
                    per poder treballar aspectes concrets.
                </p>
                <p>
                    El grup de treball es dividirà en <strong>3 estacions diferents</strong>, i cada
                    grup passarà per totes les estacions en una durada aproximada d'1 hora i mitja
                    cada una:
                </p>
                <ul className="space-y-5">
                    <li>
                        <strong>Estació 1: TEMARI</strong> <br />
                        Simulacre de 30 preguntes de temari en 30 minuts. Aquesta prova es supervisa
                        per un docent especialista en temari de CME. Un cop acabat el simulacre i
                        obtinguda la teva puntuació, el docent repassarà una per una cada pregunta,
                        explicant el perquè de cada resposta i analitzant les altres respostes en
                        quines possibles preguntes poden aparèixer. És a dir, és una manera d'
                        <strong>ESTUDIAR IDEAL</strong>.
                    </li>
                    <li>
                        <strong>Estació 2: PSICOS</strong>
                        <br />
                        Simulacre de 80 preguntes en 30 minuts. Aquesta prova es supervisa per un
                        docent especialista, psicòleg i agent CME en aquesta matèria. Es realitzarà
                        exactament igual que en el temari: es repassaran i es resoldran dubtes de
                        com resoldre exercicis psicotècnics un cop acabat el simulacre.
                    </li>
                    <li>
                        <strong>Estació 3: FÍSIQUES</strong>
                        <br />
                        Aquesta prova està supervisada per un formador oficial del cos CME, que
                        donarà tips i consells per afrontar les proves. A continuació, realitzareu
                        un simulacre tant de circuit, press banca i curs navette. Aquesta estació
                        contempla una duració inferior per poder donar temps a fer una dutxa ràpida
                        i canviar d'estació.
                        <br />
                        També sortireu tots amb la vostra fitxa i els resultats obtinguts.
                    </li>
                </ul>
                <p>
                    En resum, <strong>5 hores de treball MOLT INTENS</strong> on podeu preparar{" "}
                    <strong>TOTES LES PARTS</strong> de l'oposició en una mateixa tarda i, el més
                    important, en un entorn espectacular on volem formar una pinya increïble fins al
                    final d'aquesta oposició. A més, estareu sempre acompanyats de docents d'alt
                    nivell, tots policies i especialistes en les seves matèries.
                </p>
                <p>
                    Les estacions i les sessions seran adaptatives. A mesura que avanci l'oposició,
                    anirem introduint estacions dedicades a la fase de preparació de test de
                    personalitat, biodata, entrevista i altres.
                </p>
                <p>
                    <strong>
                        Vols posar-te a prova acompanyat d'un grup de companys i companyes increïble
                        i assumir el teu objectiu?
                    </strong>
                </p>
                <p className="text-green-800 font-semibold text-xl">
                    🗓 Propera sessió: DISSABTE 3 DE MAIG
                    <br />
                    ⏰ Horari: 16:00 a 21:00
                    <br />
                    📍 Lloc: CROSSIFT LYNX C/ Francesc Vila 17, Sant Cugat del Vallès.
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Posa’t a prova com mai abans! 💪🔥 En només 5 hores, prepara totes les parts de l’oposició amb docents especialitzats i una metodologia única. Reserva la teva plaça ara i assegura el teu futur!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
